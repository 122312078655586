import { Box, H3, Text } from '@bfa/common-ui';
import React, { FC, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LoginBannerModule } from '../../types/index';
import GetStartedButton from '../HeroSection/GetStartedButton';
import LoginForm from './LoginForm';
import {
  LoginBannerCta,
  LoginBannerModal,
  LoginBannerModalCloseBtn,
  LoginBannerModalContainer,
  LoginBannerModalItem,
  LoginBannerModalItems,
  LoginBannerModalSubTitle,
  LoginBannerModalTitle,
  LoginModalBanner,
  LoginModalBannerSubTitle,
  LoginModalBannerTitle,
  StyledModal,
} from './LoginForm.styled';

export type PropTypes = {
  isOpen: boolean;
  onDismiss: () => void;
  banner?: LoginBannerModule | null;
  recaptchaEnabled: boolean;
  showSignupLink?: boolean;
};

const LoginModal: FC<PropTypes> = ({
  isOpen,
  onDismiss,
  banner,
  recaptchaEnabled,
  showSignupLink,
}) => {
  const [isLoginBannerVisible, setIsLoginBannerVisible] =
    useState<boolean>(false);

  const handleBannerCtaClick = () => {
    setIsLoginBannerVisible(true);
  };

  return (
    <>
      <StyledModal
        showDismiss
        onDismiss={onDismiss}
        isOpen={isOpen}
        minWidth="100%"
      >
        {banner && (
          <LoginModalBanner>
            <LoginModalBannerTitle>{banner?.bannerTitle}</LoginModalBannerTitle>
            <LoginModalBannerSubTitle>
              {banner?.bannerSubtitle}
            </LoginModalBannerSubTitle>
            <LoginBannerCta onClick={handleBannerCtaClick}>
              {banner?.bannerCtaText}
            </LoginBannerCta>
          </LoginModalBanner>
        )}
        <LoginForm title="Welcome Back" recaptchaEnabled={recaptchaEnabled} />
        {showSignupLink && (
          <Box
            py="2rem"
            px={['1.5rem', '4rem']}
            bg="grayLightest"
            textAlign="center"
            data-testid="login-modal-signup-section"
          >
            {/* TODO Fix after removing ipsy-core-styles overrides */}
            <Box mb={20}>
              <H3 textAlign="center" color="grayDarkest">
                Not a member? Sign up Now!
              </H3>
            </Box>
            <GetStartedButton
              main
              secondary
              medium
              fullWidth
              type="LOGIN"
              data-e2e-selector={'login-splash-button-register'}
              margin="0 auto"
            >
              Get Started
            </GetStartedButton>
          </Box>
        )}
      </StyledModal>
      {banner && (
        <LoginBannerModal isOpen={isLoginBannerVisible}>
          <LoginBannerModalContainer>
            <LoginBannerModalTitle>
              {banner?.bannerCtaModalTitle}
            </LoginBannerModalTitle>
            <LoginBannerModalSubTitle>
              {banner?.bannerCtamodalSubtitle}
            </LoginBannerModalSubTitle>
            <LoginBannerModalItems>
              {ReactHtmlParser(banner?.bannerCtaModalItems as string).map(
                (p, index) => {
                  return (
                    <LoginBannerModalItem key={index}>
                      <div className="icon-ipsy icon-ipsy-check-med" />
                      {p}
                    </LoginBannerModalItem>
                  );
                }
              )}
            </LoginBannerModalItems>
          </LoginBannerModalContainer>
          <LoginBannerModalCloseBtn>
            <Text
              color="accessiblePink"
              bold
              onClick={() => setIsLoginBannerVisible(false)}
            >
              {banner?.bannerCtaModalAcknowledgeText}
            </Text>
          </LoginBannerModalCloseBtn>
        </LoginBannerModal>
      )}
    </>
  );
};

export default LoginModal;
