import { H1, H2, H4, Modal, Text } from '@bfa/common-ui';
import styled from 'styled-components';

export const StyledForm = styled.form`
  margin: 0 auto;
  max-width: 560px;
`;

export const LoginModalBanner = styled.div`
  padding: 2.4rem 1.5rem 1.4rem;
  margin-top: -3rem;
  text-align: center;
  margin-bottom: 0.77rem;
  background: radial-gradient(
    112.4% 666.73% at 100% 100.02%,
    #fdf0e4 0%,
    #f1e9fe 35.94%,
    #f1e9fe 65.06%,
    #8d92f8 100%
  );
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
`;

export const LoginModalBannerTitle = styled(H2)``;
export const LoginModalBannerSubTitle = styled(Text).attrs({ as: 'p' })`
  margin-top: 1.6rem;
`;

export const LogInTitle = styled(H1)`
  text-align: center;
  font-size: 2.2rem;
`;

export const LoginBanner = styled.div`
  height: 10rem;
`;
export const LoginBannerModal = styled(Modal)`
  text-align: center;
`;

export const LoginBannerModalContainer = styled.div`
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
`;

export const LoginBannerModalTitle = styled(H2)``;
export const LoginBannerModalSubTitle = styled(Text).attrs({ as: 'p' })`
  margin-top: 1.2rem;
  padding: 0 1.6rem;
`;
export const LoginBannerModalItems = styled.div`
  text-align: left;
  margin-bottom: 2rem;
`;
export const LoginBannerModalItem = styled.div`
  display: flex;
  div {
    color: ${(p) => p.theme.colors.accessiblePink};
    padding-top: 0.4rem;
    margin-right: 1.2rem;
  }
`;

export const LoginBannerModalCloseBtn = styled.div`
  border-top: 0.1rem solid #dddddd;
  padding: 2rem 0;
  cursor: pointer;
`;

export const LoginBannerCta = styled(H4)`
  color: ${(p) => p.theme.colors.accessiblePink};
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
`;

export const HrOr = styled.hr`
  border-top: 1px solid ${(p) => p.theme.colors.grayLight};
  text-align: center;
  width: 180px;
  margin: 25px auto;
  overflow: visible;
  &:after {
    content: 'OR';
    display: inline-block;
    position: relative;
    top: -8px;
    padding: 0 20px;
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.grayMedium};
    font-size: 16px;
    font-family: 'NeueEinstellungBold';
  }
`;

export const StyledModal = styled(Modal)`
  div.Modal {
    position: absolute;
    top: 0;
    height: 100vh;
    & > div {
      height: 100%;
      max-height: 100vh;
      display: flex;
      flex-direction: column;
    }
  }
  div.Overlay {
    top: 5.8rem;
  }

  @media only screen and (min-width: 560px) {
    div.Modal {
      position: static;
      max-height: 90vh;
      height: auto;
    }
    div.Overlay {
      top: 0;
    }
  }
`;

export const LoginFormWrapper = styled.div`
  max-width: 560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  flex: 1;
`;
